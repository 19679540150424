import React from "react";
import Layout from "../components/layout";
import Nav from "../components/nav";
import SEO from "../components/seo";
import FeedFB from '../components/Feed-FB';
import './news.css';

const lang = {
  en: {
    header: 'News',
    content: 'Here we will show news and articles about what is new about diving in the Baltic Sea, as well as a blog where we write about our excursions.'
  },
  sv: {
    header: 'Nyheter',
    content: 'Här kommer vi visa nyheter och artiklar över vad som är nytt gällande dykning i Östersjön, samt en blogg där vi skriver om våra utflykter.'
  },
};

const News = () => (
  <Layout>
    <SEO title="News on Baltic sea diving" description="Stay up to date with whats new at baltic sea diving" />
    <Nav />
    <div className="news__header"></div>
    <div className="news__section">
      <div className="news__box">
        <h1>{lang.en.header}</h1>
        <p className="news__content">{lang.en.content}</p>
      </div>
    </div>
    <div className="news__facebook-feed">
      <FeedFB />
    </div>
  </Layout>
)

export default News;